@font-face {
    font-family: aileron;
    src        : url(assets/fonts/aileron.thin.otf);
    font-weight: normal;
    font-style : normal;
}

@font-face {
    font-family: Bandal;
    src        : url(assets/fonts/Bandal.ttf);
    font-weight: bold;
    font-style : normal;
}


body{
    background: #FEFEFE;
    font-family: aileron;
}

.barra-flotante{
    position: absolute;
    width: 100%;
    z-index: 3;
    top: 130px;
}

.header-brand{
    background: #285474;
}